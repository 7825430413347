// @ts-ignore
import styles from './styles.module.css'
import Accordion from 'react-bootstrap/Accordion'
import Nav from 'react-bootstrap/Nav'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { MEDIA_QUERY_LAPTOP, useMediaQuery } from '../../../hooks/useMediaQuery'
import Hamburger from '../../icons/Hamburger'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from '../../../routes/AppRoutes'
import { useNavigate } from 'react-router-dom'
import IconSideBar from '../../icons/IconSideBar'

interface Props {
    show: boolean
    handleClose: () => void
    handleShow: () => void
}

const SideBar: React.FC<Props> = props => {
    const { handleClose, handleShow, show } = props
    const isLaptop = useMediaQuery(MEDIA_QUERY_LAPTOP)
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const handleClick = (e: any, url: string) => {
        e.preventDefault()
        if (isLaptop) {
            handleClose()
        }
        navigate(url)
    }

    const renderSidebar = () => (
        <div className='sidebar'>
            <div className={styles.wrapper}>
                <div className={styles.list}>
                    <div className={styles.item}>
                        <Accordion>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>
                                    <h5>
                                        <Link
                                            to={AppRoutes.introduction}
                                            onClick={e =>
                                                handleClick(
                                                    e,
                                                    AppRoutes.introduction
                                                )
                                            }
                                        >
                                            <div className={styles.header}>
                                                <span className={styles.icon}>
                                                    <IconSideBar
                                                        color={
                                                            pathname ===
                                                            AppRoutes.introduction
                                                                ? '#1890ff'
                                                                : '#fff'
                                                        }
                                                    />
                                                </span>
                                                <span
                                                    style={{
                                                        color:
                                                            pathname ===
                                                            AppRoutes.introduction
                                                                ? '#1890ff'
                                                                : '#fff'
                                                    }}
                                                    className={styles.title}
                                                >
                                                    Introduction
                                                </span>
                                            </div>
                                        </Link>
                                    </h5>
                                </Accordion.Header>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className={styles.item}>
                        <Accordion
                            defaultActiveKey={
                                pathname === AppRoutes.gettingStarted ||
                                pathname === AppRoutes.api
                                    ? '1'
                                    : ''
                            }
                        >
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header>
                                    <h5 className='h5'>
                                        <div className={styles.header}>
                                            <span className={styles.icon}>
                                                <IconSideBar />
                                            </span>
                                            <span className={styles.title}>
                                                Overview
                                            </span>
                                        </div>
                                    </h5>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Nav.Item style={{ paddingLeft: '30px' }}>
                                        <Nav.Link
                                            onClick={e =>
                                                handleClick(
                                                    e,
                                                    AppRoutes.gettingStarted
                                                )
                                            }
                                            className={styles.title}
                                            href={AppRoutes.gettingStarted}
                                            style={{
                                                color:
                                                    pathname ===
                                                    AppRoutes.gettingStarted
                                                        ? '#1890ff'
                                                        : '#fff'
                                            }}
                                        >
                                            Getting Started
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item style={{ paddingLeft: '30px' }}>
                                        <Nav.Link
                                            onClick={e =>
                                                handleClick(e, AppRoutes.api)
                                            }
                                            className={styles.title}
                                            href={AppRoutes.api}
                                            style={{
                                                color:
                                                    pathname === AppRoutes.api
                                                        ? '#1890ff'
                                                        : '#fff'
                                            }}
                                        >
                                            SoftNote methods
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item style={{ paddingLeft: '30px' }}>
                                        <Nav.Link
                                            onClick={e =>
                                                handleClick(
                                                    e,
                                                    AppRoutes.SoftnoteImages
                                                )
                                            }
                                            className={styles.title}
                                            href={AppRoutes.api}
                                            style={{
                                                color:
                                                    pathname ===
                                                    AppRoutes.SoftnoteImages
                                                        ? '#1890ff'
                                                        : '#fff'
                                            }}
                                        >
                                            Softnote Images
                                        </Nav.Link>
                                    </Nav.Item>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className={styles.item}>
                        <Accordion>
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header>
                                    <h5>
                                        <Link
                                            to={AppRoutes.integration}
                                            onClick={e =>
                                                handleClick(
                                                    e,
                                                    AppRoutes.integration
                                                )
                                            }
                                        >
                                            <div className={styles.header}>
                                                <span className={styles.icon}>
                                                    <IconSideBar
                                                        color={
                                                            pathname ===
                                                            AppRoutes.integration
                                                                ? '#1890ff'
                                                                : '#fff'
                                                        }
                                                    />
                                                </span>
                                                <span
                                                    style={{
                                                        color:
                                                            pathname ===
                                                            AppRoutes.integration
                                                                ? '#1890ff'
                                                                : '#fff'
                                                    }}
                                                    className={styles.title}
                                                >
                                                    Integration
                                                </span>
                                            </div>
                                        </Link>
                                    </h5>
                                </Accordion.Header>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className={styles.item}>
                        <Accordion>
                            <Accordion.Item eventKey='2'>
                                <Accordion.Header>
                                    <h5>
                                        <Link
                                            to={AppRoutes.CEXIntegration}
                                            onClick={e =>
                                                handleClick(
                                                    e,
                                                    AppRoutes.CEXIntegration
                                                )
                                            }
                                        >
                                            <div className={styles.header}>
                                                <span className={styles.icon}>
                                                    <IconSideBar
                                                        color={
                                                            pathname ===
                                                            AppRoutes.CEXIntegration
                                                                ? '#1890ff'
                                                                : '#fff'
                                                        }
                                                    />
                                                </span>
                                                <span
                                                    style={{
                                                        color:
                                                            pathname ===
                                                            AppRoutes.CEXIntegration
                                                                ? '#1890ff'
                                                                : '#fff'
                                                    }}
                                                    className={styles.title}
                                                >
                                                    CEXIntegration
                                                </span>
                                            </div>
                                        </Link>
                                    </h5>
                                </Accordion.Header>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className={styles.item}>
                        <Accordion>
                            <Accordion.Item eventKey='3'>
                                <Accordion.Header>
                                    <h5>
                                        <Link
                                            to={AppRoutes.DEXIntegration}
                                            onClick={e =>
                                                handleClick(
                                                    e,
                                                    AppRoutes.DEXIntegration
                                                )
                                            }
                                        >
                                            <div className={styles.header}>
                                                <span className={styles.icon}>
                                                    <IconSideBar
                                                        color={
                                                            pathname ===
                                                            AppRoutes.DEXIntegration
                                                                ? '#1890ff'
                                                                : '#fff'
                                                        }
                                                    />
                                                </span>
                                                <span
                                                    style={{
                                                        color:
                                                            pathname ===
                                                            AppRoutes.DEXIntegration
                                                                ? '#1890ff'
                                                                : '#fff'
                                                    }}
                                                    className={styles.title}
                                                >
                                                    DEXIntegration
                                                </span>
                                            </div>
                                        </Link>
                                    </h5>
                                </Accordion.Header>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    )

    if (isLaptop)
        return (
            <>
                <div onClick={handleShow}>
                    <Hamburger />
                </div>

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>{renderSidebar()}</Offcanvas.Body>
                </Offcanvas>
            </>
        )

    return renderSidebar()
}

export default SideBar

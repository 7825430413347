import { MEDIA_QUERY_TABLET, useMediaQuery } from '../../../hooks/useMediaQuery'
import NavBottomComponent from './component'

const NavBottom: React.FC = (): React.ReactElement | null => {
    const isMobile = useMediaQuery(MEDIA_QUERY_TABLET)

    if (!isMobile) {
        return null
    }

    return <NavBottomComponent />
}
export default NavBottom

// @ts-ignore
import styles from './styles.module.css'

const startingList = [
    {
        title: 'Authorization in SoftNote Wallet.',
        body: 'First, log in to your Wallet account. This is the authorization step that confirms you have the required permissions to generate an API key.'
    },
    {
        title: 'Click on the menu in the upper right corner and select Settings.'
    },
    {
        title: 'Navigate to "For developers" section.'
    },
    {
        title: 'Click on the " API Key" button to proceed.'
    },
    {
        title: 'After clicking the button, your permanent API key will appear. Click the "Copy" button to copy your API code to the clipboard. Make sure to save this key securely, as you will use it for API-related tasks.'
    }
]

const GettingStarted: React.FC = () => {
    return (
        <div>
            <div className='pageTitle'>Getting Started</div>
            <div>
                <div>How to Obtain an API Key Through SoftNote Wallet</div>
                <div className={styles.text}>
                    Follow these steps to obtain your API key for developers via
                    SoftNote Wallet.
                </div>
                <div className='pageSubTitle'>Steps</div>
                <ol className={styles.list}>
                    {startingList.map(({ title, body }, i) => {
                        return (
                            <li className={styles.item} key={i}>
                                <div className={styles.itemTitle}>{title}</div>
                                <div className={styles.itemBody}>{body}</div>
                            </li>
                        )
                    })}
                </ol>
                <div className='pageSubTitle'>Important Note</div>
                <div className={styles.text}>
                    Your API key is sensitive information. Do not share it and
                    keep it secure.
                </div>
                <div className={styles.text}>
                    That's it! You've successfully obtained your API key through
                    SoftNote Wallet.
                </div>
            </div>
        </div>
    )
}

export default GettingStarted

// @ts-ignore
import styles from './styles.module.css'
// @ts-ignore
import Img1 from '../../assets/imgs/CEXInterfaceBuy.png'
// @ts-ignore
import Img2 from '../../assets/imgs/CEXInterfaceSell.png'
// @ts-ignore
import Img3 from '../../assets/imgs/3.png'
// @ts-ignore
import Img8 from '../../assets/imgs/8.jpg'
import { useState } from 'react'
import ModalImg from '../../com/shared/Modals/ModalImg'

// data

export interface Img {
    src: string
    desc?: string
}

const img8: Img = {
    src: Img8
}

const imgsCexIntegration: Img[] = [
    {
        src: Img1
    },
    {
        src: Img2
    },
    {
        src: Img3
    }
]

const CEXIntegrationPage: React.FC = () => {
    const [activeImg, setActiveImg] = useState<Img>(imgsCexIntegration[0])
    const [isOpen, setOpen] = useState<boolean>(false)
    const handleClick = (img: Img) => {
        setActiveImg(img)
        setOpen(true)
    }
    return (
        <>
            <div>
                <div className='pageTitle'>
                    SoftNote API Integration Workflow for Centralized Exchanges
                    (CEXs)
                </div>
                <ol className={styles.ul}>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b>Registration and Authentication</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Registration:</b> Start by registering for a
                                Softnote account at{' '}
                                <a href='https://softnote.com/'>softnote.com</a>
                                .
                            </li>
                            <li className={styles.text}>
                                <b>Authorization in SoftNote Wallet:</b> Log in
                                to your SoftNote Wallet account or create a new
                                account. This is the authorization step that
                                confirms you have the required permissions to
                                generate an API key.
                            </li>
                            <li className={styles.text}>
                                <b>Navigate to "For developers" section:</b>{' '}
                                Click on the menu in the upper right corner and
                                select Settings, locate the "For developers"
                                section
                            </li>
                            <li className={styles.text}>
                                <b>Generate API Key Button:</b> Click on the "
                                API Key" button to proceed.
                            </li>
                            <li className={styles.text}>
                                <b>Retrieve Permanent Key:</b> After clicking
                                the button, your permanent API key will appear.
                                Click the "Copy" button to copy your API code to
                                the clipboard. Make sure to save this key
                                securely, as you will use it for API-related
                                tasks.
                            </li>
                            <li className={styles.text}>
                                <b>Wallet Balance:</b> Confirm that your wallet
                                maintains a sufficient balance of TET T12 to
                                cover fees.
                            </li>
                        </ul>
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b>Understand Softnotes</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Concept Review:</b>Familiarize yourself with
                                the Softnote concept, including understanding
                                various denominations and the dynamic
                                passcode-based ownership transfer.
                            </li>
                            <li className={styles.text}>
                                <b>Additional Resources:</b>For further
                                information, consult the Softnote API
                                documentation provided{' '}
                                <a href='https://docs.api.softnote.com/integration'>
                                    here
                                </a>
                                . Additionally, refer to the explanatory video
                                available{' '}
                                <a href='https://www.youtube.com/watch?v=dUVWU2KNq6g'>
                                    here
                                </a>
                                . You can also see an example of a off wallet
                                softnote for the purpose of understanding how
                                the concept works{' '}
                                <a href='https://softnote.tectum.io/en/bitcoin/sch256/btc/00000820504'>
                                    here
                                </a>
                                .
                            </li>
                        </ul>
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b>Softnote Trading Pair Configuration</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Determine Trading Pair:</b> Decide on your
                                Softnote listing/trading pair. These units are
                                represented as (layer 1 currency + sn), e.g.,
                                BTCsn. For instance, a BTC Softnote trading pair
                                with USDT would be listed as BTCsn/USDT.
                            </li>
                            <li className={styles.text}>
                                <b>Listing Pair Setup:</b> Configure the trading
                                pair, allowing limit buy and sell orders for
                                Softnote denominations. Example denominations
                                for BTC: 0.0001, 0.0002, 0.0005, 0.001, 0.002,
                                0.005, 0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2,
                                and 5 BTC.
                            </li>
                        </ul>
                        {imgsCexIntegration.map((el: Img) => (
                            <div
                                key={el.src}
                                onClick={() => handleClick(el)}
                                className={styles.imgWrap}
                            >
                                <img
                                    src={el.src}
                                    alt=''
                                    className={styles.img}
                                />
                            </div>
                        ))}
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b>Trading Functions</b>
                        </div>
                        <ul className={styles.ol}>
                            <div className={styles.text}>
                                <b>Enable Buy and Sell Orders:</b> Implement
                                functionality that enables users to create buy
                                and sell orders based on specific Softnote
                                denominations.
                            </div>
                        </ul>
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b>User Softnote Deposit</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Create Deposit Interface:</b> Develop a user
                                interface that allows users to deposit Softnotes
                                by entering the serial number and passcode.
                            </li>
                            <li className={styles.text}>
                                <b>API POST for Passcode Change:</b> Utilize the
                                API's POST method for changing Softnote
                                passcodes to approve passcode deposits
                            </li>
                            <li className={styles.text}>
                                <b>Passcode Handling:</b> If the passcode is
                                accepted and a new passcode is supplied,
                                securely store the new passcode in an encrypted
                                format, linking it to the serial number. Assign
                                the Softnote as a deposit to the user's account
                                or UID.
                            </li>
                            <li className={styles.text}>
                                <b>Privacy:</b> Ensure that depositing users do
                                not have visibility of the new passcode.
                            </li>
                        </ul>
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b>Sell Order</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Enable Listing as Sell Order:</b> Allow users
                                who have deposited Softnotes to list them as
                                sell orders, setting their desired selling
                                prices in the corresponding trading pair.
                            </li>
                        </ul>
                        <div
                            onClick={() => handleClick(imgsCexIntegration[0])}
                            className={styles.imgWrap}
                        >
                            <img
                                src={imgsCexIntegration[0].src}
                                alt=''
                                className={styles.img}
                            />
                        </div>
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b>Buy Order</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Facilitate Buy Orders:</b> Enable users to
                                create buy orders with the relevant Softnote
                                denominations or manually execute purchases from
                                existing sell orders for Softnotes.
                            </li>
                            <li className={styles.text}>
                                <b>Ownership Transfer:</b> After a successful
                                buy order execution, transfer assigned ownership
                                of the the Softnote's serial number from the
                                seller's account to the buyer's account. No
                                passcode change is required.
                            </li>
                        </ul>
                        <div
                            onClick={() => handleClick(imgsCexIntegration[1])}
                            className={styles.imgWrap}
                        >
                            <img
                                src={imgsCexIntegration[1].src}
                                alt=''
                                className={styles.img}
                            />
                        </div>
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b> User Softnote Withdrawal</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Withdrawal Interface:</b> Create a
                                user-friendly interface that enables buyers who
                                have Softnotes in their accounts to initiate
                                withdrawals.
                            </li>
                            <li className={styles.text}>
                                <b>Passcode Reveal:</b> Once the user confirms
                                the withdrawal, reveal the passcode and serial
                                number. Remind the user to securely store their
                                passcode.
                            </li>
                        </ul>
                        <div
                            onClick={() => handleClick(img8)}
                            className={styles.imgWrap}
                        >
                            <img src={Img8} alt='' className={styles.img} />
                        </div>
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b>Fee Payment Handling</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Fee Structure:</b> Charge passcode changes
                                from the developer's Softnote wallet at 0.1% of
                                the face value of the exchanged Softnote.
                            </li>
                            <li className={styles.text}>
                                <b>Customizable Fees:</b> Allow developers to
                                determine and charge fees for deposits and
                                withdrawals in their preferred tokens.
                            </li>
                        </ul>
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b> Education about Softnotes</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Educational Section:</b> Consider adding an
                                educational section on the main trading pair
                                page to educate users about Softnotes, their
                                advantages, and how to use them effectively.
                            </li>
                        </ul>
                    </li>
                    <li className={styles.liItem}>
                        <div className='pageSubTitle'>
                            <b>Conclusion</b>
                        </div>
                        <ul className={styles.ol}>
                            <li className={styles.text}>
                                <b>Flexibility:</b> Keep in mind that this
                                workflow serves as a guide, and developers may
                                make adjustments as needed to align with their
                                users and interface requirements. Some
                                developers may choose to implement two passcode
                                changes, one at deposit and one at withdrawal.
                            </li>
                        </ul>
                    </li>
                </ol>
                <div className={styles.text}>
                    This workflow outlines the integration steps for Centralized
                    Exchanges to seamlessly incorporate the Softnote API into
                    their platform, providing users with an efficient and secure
                    way to trade Softnotes. Developers are encouraged to tailor
                    this workflow to best suit their specific user interface and
                    functionality preferences.
                </div>
            </div>
            <ModalImg img={activeImg.src} setShow={setOpen} show={isOpen} />
        </>
    )
}

export default CEXIntegrationPage

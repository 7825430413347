import { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AppRoutes } from './routes/AppRoutes'
import { Container } from 'react-bootstrap'
import SideBar from './com/layout/SideBar'
import GettingStarted from './pages/GettingStarted'
import Introduction from './pages/Introduction'
import SoftNoteApi from './pages/SoftNoteApi'
import Header from './com/layout/Header'
import IntegrationPage from './pages/Integration'
import CEXIntegrationPage from './pages/CEXIntegration'
import NavBottom from './com/layout/NavBottom'
import DEXIntegrationPage from './pages/DEXIntegration'
import SoftnoteImages from './pages/SoftnoteImages'

const App: React.FC = () => {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const routes = [
        { path: AppRoutes.introduction, element: <Introduction /> },
        { path: AppRoutes.gettingStarted, element: <GettingStarted /> },
        { path: AppRoutes.api, element: <SoftNoteApi /> },
        { path: AppRoutes.integration, element: <IntegrationPage /> },
        { path: AppRoutes.CEXIntegration, element: <CEXIntegrationPage /> },
        { path: AppRoutes.DEXIntegration, element: <DEXIntegrationPage /> },
        { path: AppRoutes.SoftnoteImages, element: <SoftnoteImages /> }
    ]

    return (
        <>
            <Header />
            <Container>
                <div className='header'>
                    <div className='title'>
                        Welcome to the Softnote APIs documentation
                    </div>
                </div>
                <div className='wrapper'>
                    <div className='leftCol'>
                        <SideBar
                            show={show}
                            handleClose={handleClose}
                            handleShow={handleShow}
                        />
                    </div>

                    <Routes>
                        {routes.map(item => (
                            <Route
                                key={item.path}
                                path={item.path}
                                element={item.element}
                            />
                        ))}
                    </Routes>
                </div>
            </Container>
            <NavBottom />
        </>
    )
}

export default App

import NavBarMobile from '../NavBar/NavBarMobile'
// @ts-ignore
import styles from './styles.module.css'

const NavBottomComponent: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <NavBarMobile />
            </div>
        </div>
    )
}
export default NavBottomComponent

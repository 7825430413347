import RequestType from './RequestHeader'
import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion'
import { Param } from '../../../data/types'
// @ts-ignore
import styles from './styles.module.css'

interface Props {
    type: 'get' | 'put' | 'post' | 'delete'
    url: string
    desc: string
    parametrs: Param[] | undefined
    code?: any
}

const Request: React.FC<Props> = props => {
    const { desc, type, url, parametrs, code } = props
    return (
        <div className='acordionItemWrap'>
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>
                        <RequestType type={type} url={url} desc={desc} />
                    </Accordion.Header>
                    <Accordion.Body>
                        {parametrs ? (
                            <div>
                                <h6>Parameters</h6>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {parametrs.map((el: Param) => (
                                            <tr
                                                className={styles.tableRow}
                                                key={el.name}
                                            >
                                                <>
                                                    <td>{el.name}</td>
                                                    <td>{el.type}</td>
                                                    <td>{el.desc}</td>
                                                </>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            ''
                        )}
                        <div>
                            <h6>Responses</h6>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {code.map((el: any) => (
                                        <tr
                                            className={styles.tableRow}
                                            key={el.status}
                                        >
                                            <td>{el.status}</td>
                                            <td>
                                                <h6>Success</h6>
                                                <div>
                                                    <pre>
                                                        {JSON.stringify(
                                                            el.code,
                                                            null,
                                                            2
                                                        )}
                                                    </pre>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default Request

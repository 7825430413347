// @ts-ignore
import styles from './styles.module.css'

const IntegrationPage: React.FC = () => {
    return (
        <div>
            <div className='pageTitle'>The Tectum Softnote API is Live!</div>
            <div className={styles.text}>
                Are you looking to provide your users with lightning-fast and
                nearly fee-free Bitcoin transfers? Look no further!
            </div>
            <div className={styles.text}>
                Introducing Softnote, the groundbreaking new product from Tectum
                that facilitates the instantaneous transfer of Bitcoin.
            </div>
            <div className='pageSubTitle'>The Softnote</div>
            <div className={styles.text}>
                The Softnote is the world's first NDFT (Non Divisible Fungible
                Token). The Softnote operates by transmitting Bitcoin in various
                denominations, including 0.0001, 0.0002, 0.0005, 0.001, 0.002,
                0.005, 0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, and 5 BTC. The
                private keys of these wallets are encrypted on the Tectum
                blockchain, and ownership is transferred using a dynamic
                passcode that changes through zero-knowledge proof to validate
                the change of ownership.
            </div>
            <div className={styles.text}>
                BTC Softnote represents the world's first walletless, anonymous,
                instant, and trustless Bitcoin system!
            </div>
            <div className={styles.text}>
                BTC Softnotes are burned by applying the current dynamic
                passcode and executing a burn function, providing the user with
                the private keys of that specific Softnote Bitcoin wallet. Fees
                are charged at 0.1% of the face value in Tectum's native token,
                $TET. This means that any platform has the flexibility to charge
                Bitcoin Softnote fees in their own native token for an enhanced
                fee structure while settling the Tectum blockchain fees in $TET.
            </div>
            <div className={styles.text}>
                To view an example of a 0.001 Softnote off-wallet, please click{' '}
                <a href='https://softnote.tectum.io/en/bitcoin/sch256/btc/00000820504'>
                    here
                </a>
                .
            </div>
            <div className='pageSubTitle'>API</div>
            <div className={styles.text}>
                Our Softnote API is now live! It retrieves all the data for a
                particular Softnote from the Tectum blockchain and enables a
                platform to modify the passcodes for each Softnote. You can find
                the API documentation here.
            </div>
            <div className='pageSubTitle'>DEX's (Decentralized Exchanges)</div>
            <div className={styles.text}>
                DEX's can leverage the API to list Bitcoin Softnotes in various
                denominations, allowing users to buy and sell them seamlessly.
                Users can load Softnotes using their serial number and passcode
                and place sell orders.
            </div>
            <div className={styles.text}>
                Buyers can then purchase BTC Softnotes in the currency
                determined by the DEX. The DEX simply provides the user with the
                new passcode for the Softnote, which can then be exported to the
                buyer's chosen storage method.
            </div>
            <div className='pageSubTitle'>CEX's (Centralized Exchanges)</div>
            <div className={styles.text}>
                CEX's can create listing pairs of BTC Softnotes with any other
                currency, enabling users to load Softnotes and offer sell
                orders. Buyers can receive these Softnotes in their wallet and
                manually export them via the serial number and passcode.
            </div>
            <div className='pageSubTitle'>Wallets</div>
            <div className={styles.text}>
                Wallet platforms can now seamlessly integrate Softnotes,
                allowing users to send Softnotes to others within the platform
                by sending new passcodes to the recipient. This means any wallet
                that integrates Softnotes can proudly advertise itself as the
                first platform to offer instant and anonymous Bitcoin transfers.
            </div>
            <div className='pageSubTitle'>Conclusion</div>
            <div className={styles.text}>
                By integrating Softnotes via our API, your platform can offer
                users a revolutionary and hassle-free way to transfer Bitcoin.
                Join us in bringing the future of Bitcoin transfers to your
                users and enjoy the benefits of offering this innovative
                solution. Don't miss out on becoming a pioneer in the world of
                instant and anonymous cryptocurrency transfers with Softnotes
            </div>
        </div>
    )
}

export default IntegrationPage

// @ts-ignore
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'

interface Props {
    img: string
    desc?: string
    show: boolean
    setShow: (val: boolean) => void
}

const ModalImg: React.FC<Props> = props => {
    const { img, desc, setShow, show } = props

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{desc}</Modal.Title>
                </Modal.Header>

                <Image src={img} />
            </Modal>
        </>
    )
}

export default ModalImg

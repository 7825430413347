import { useEffect, useMemo, useState } from 'react'

export const MEDIA_QUERY_LAPTOP = '(max-width: 940px)'
export const MEDIA_QUERY_TABLET = '(max-width: 992px)'
export const MEDIA_QUERY_MOBILE = '(max-width: 576px)'

export function useMediaQuery(mediaQueryString: string) {
    const queryString = removeReservedMediaKeyWord(mediaQueryString)
    const query = useMemo(() => window.matchMedia(queryString), [queryString])
    const [matches, setMatches] = useState(query.matches)

    useEffect(() => {
        const listener = (e: any) => setMatches(e.matches)
        query.addEventListener('change', listener)
        return () => query.removeEventListener('change', listener)
    }, [query])
    //
    return matches
}

function removeReservedMediaKeyWord(mediaQueryString: string) {
    return mediaQueryString.replace('@media', '').trim()
}

import { NavLink } from 'react-router-dom'
// @ts-ignore
import styles from './styles.module.css'
import {
    IconExplorer,
    IconMerchant,
    IconSettings,
    IconWallet
} from '../../../icons/NavBarIcons'

const NavBarMobile: React.FC = (): React.ReactElement => {
    return (
        <div className={styles.wrapper}>
            <NavLink
                style={{
                    textDecoration: 'none'
                }}
                to={String(process.env.REACT_APP_WALLET_URL)}
                className={styles.link}
            >
                <IconWallet />
                <span>SoftNote Wallet</span>
            </NavLink>
            <NavLink
                style={{
                    textDecoration: 'none'
                }}
                to={String(process.env.REACT_APP_MERCHANT_URL)}
                className={styles.link}
            >
                <IconMerchant />
                <span>Merchant Space</span>
            </NavLink>
            <NavLink
                style={{
                    textDecoration: 'none'
                }}
                to={String(process.env.REACT_APP_EXPLORER_URL)}
                className={styles.link}
            >
                <IconExplorer />
                <span>Tectum Explorer</span>
            </NavLink>
            <NavLink
                style={{
                    textDecoration: 'none'
                }}
                to={String(process.env.REACT_APP_TECTUM_PASSPORT_URL)}
                className={styles.link}
            >
                <IconSettings />
                <span>Settings</span>
            </NavLink>
        </div>
    )
}
export default NavBarMobile

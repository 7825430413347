import { ApiData } from './types'

export const apiData: ApiData[] = [
    {
        parametrs: [
            {
                name: 'status',
                desc: 'SoftNote status (From "/softnote-statuses") ',
                type: 'String'
            },
            { name: 'page', desc: 'Page', type: 'Number' },
            { name: 'limit', desc: 'Limit', type: 'Number' },
            { name: 'address', desc: 'SoftNote Address', type: 'String' },
            {
                name: 'currency',
                desc: 'Currency (From "/softnote-currencies")',
                type: 'String'
            },
            { name: 'type', desc: '', type: '0 or 1' }
        ],
        type: 'get',
        url: '/softnotes',
        desc: "Get SoftNote's list",
        code: [
            {
                status: 200,
                code: {
                    total: 4,
                    network: 'bitcoin',
                    protocol: 'sch256',
                    ticker: 'btc',
                    data: [
                        {
                            id: 653019,
                            nominal: 0.01,
                            address: '3BJ5ENyKFJv4CdvwYCvQ25acB3z8AtnF4V',
                            serial: '0000100000653019',
                            status: 10,
                            type: 1,
                            bundle_number: 0
                        },
                        {
                            id: 680673,
                            nominal: 0.01,
                            address: '3AkYUhLcBngNoYPDsqSiPX7Wuy9a1j2XJ7',
                            serial: '0000100000680673',
                            status: 10,
                            type: 1,
                            bundle_number: 0
                        }
                    ]
                }
            }
        ]
    },
    {
        parametrs: [
            { name: 'serial', desc: 'SoftNote serial number', type: 'String' }
        ],
        type: 'get',
        url: '/softnote',
        desc: 'Get SoftNote information by serial number',
        code: [
            {
                status: 200,
                code: {
                    id: 0,
                    nominal: 0.00001,
                    address: '3KD5e1nXhgBDxiAef5UfawCPBc6izPgs5s',
                    serial: '0000100000000000',
                    status: 1,
                    type: 0,
                    bundle_number: 0,
                    network: 'bitcoin',
                    protocol: 'sch256',
                    ticker: 'btc',
                    change_passcode_fee_value: 'number | null'
                }
            }
        ]
    },
    {
        parametrs: [
            { name: 'serial', desc: 'SoftNote serial number', type: 'String' },
            {
                name: 'passcode',
                desc: 'SoftNote passcode',
                type: 'String'
            }
        ],
        type: 'post',
        url: '/softnote-change-passcode',
        desc: 'Change SoftNote passcode',
        code: [
            {
                status: 200,
                code: {
                    passcode: 'new passcode'
                }
            }
        ]
    },
    {
        parametrs: undefined,
        type: 'get',
        url: '/softnote-statuses',
        desc: 'Get SoftNote statuses',
        code: [
            {
                status: 200,
                code: [
                    {
                        value: 1,
                        name: 'filled'
                    },
                    {
                        value: 11,
                        name: 'minted'
                    },
                    {
                        value: 10,
                        name: 'accountbased'
                    },
                    {
                        value: 0,
                        name: 'beingfiled'
                    }
                ]
            }
        ]
    },
    {
        parametrs: undefined,
        type: 'get',
        url: '/softnote-currencies',
        desc: 'Get SoftNote currencies',
        code: [
            {
                status: 200,
                code: [
                    {
                        id: 1,
                        name: 'bitcoin-sch256-btc'
                    },
                    {
                        id: 3,
                        name: 'tron-trc20-usdt'
                    },
                    {
                        id: 4,
                        name: 'tron-trc20-trx'
                    },
                    {
                        id: 6,
                        name: 'ethereum-erc20-eth'
                    }
                ]
            }
        ]
    }
]

// @ts-ignore
import styles from './styles.module.css'
import classnames from 'classnames'

interface Props {
    type: 'get' | 'put' | 'post' | 'delete'
    url: string
    desc: string
}

const RequestHeader: React.FC<Props> = props => {
    const { desc, type, url } = props

    return (
        <div className={styles.wrapper}>
            <div className={styles.col}>
                <div className={classnames(styles.type, styles[type])}>
                    {type}
                </div>
                <div onClick={e => e.stopPropagation()} className={styles.url}>
                    {url}
                </div>
            </div>
            <div className={styles.col}>
                {' '}
                <div className={styles.desc}>{desc}</div>
            </div>
        </div>
    )
}

export default RequestHeader

export const IconWallet: React.FC = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M18.5027 6.95113L16.1369 3.79672C15.4997 2.94719 14.3099 2.74338 13.4263 3.33243L7.9281 6.99791'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.5028 11.4998H21.0038C21.5563 11.4998 22.0042 11.9477 22.0042 12.5002V15.5015C22.0042 16.054 21.5563 16.5019 21.0038 16.5019H18.5028C17.1215 16.5019 16.0017 15.3821 16.0017 14.0008V14.0008C16.0017 12.6195 17.1215 11.4998 18.5028 11.4998V11.4998Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M21.0037 11.4998V9.49897C21.0037 8.11768 19.884 6.99792 18.5027 6.99792H5.49726C4.11597 6.99792 2.99622 8.11768 2.99622 9.49897V18.5027C2.99622 19.884 4.11597 21.0038 5.49726 21.0038H18.5027C19.884 21.0038 21.0037 19.884 21.0037 18.5027V16.5019'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export const IconMerchant: React.FC = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9 6.5H6C5.448 6.5 5 6.052 5 5.5V4.5C5 3.948 5.448 3.5 6 3.5H9C9.552 3.5 10 3.948 10 4.5V5.5C10 6.052 9.552 6.5 9 6.5Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M7.5 6.5V9'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M13 9V5H17V9'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M20.98 17H3.02002'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.94 16.761L19.189 9.757C19.078 9.312 18.678 9 18.219 9H5.781C5.322 9 4.922 9.312 4.811 9.757L3.06 16.761C3.02 16.92 3 17.083 3 17.246V20C3 20.552 3.448 21 4 21H20C20.552 21 21 20.552 21 20V17.246C21 17.083 20.98 16.92 20.94 16.761Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M15.5 12H16.5'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M7.5 12H8.5'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M11.5 12H12.5'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M13.5 14H14.5'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M9.5 14H10.5'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
    </svg>
)
export const IconExplorer: React.FC = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.1989 10.199V10.199C20.1929 10.199 20.9999 11.005 20.9999 12V12C20.9999 12.994 20.1939 13.801 19.1989 13.801V13.801C18.2049 13.801 17.3979 12.995 17.3979 12V12C17.3999 11.005 18.2059 10.199 19.1989 10.199Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 5.836V5.836C12.994 5.836 13.801 6.642 13.801 7.637V7.637C13.801 8.631 12.995 9.438 12 9.438V9.438C11.006 9.437 10.199 8.632 10.199 7.637V7.637C10.199 6.642 11.005 5.836 12 5.836Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 14.563V14.563C12.994 14.563 13.801 15.369 13.801 16.364V16.364C13.801 17.358 12.995 18.165 12 18.165V18.165C11.006 18.165 10.199 17.359 10.199 16.364V16.364C10.199 15.368 11.005 14.563 12 14.563Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M15.3 9.50001L13.61 8.42001'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M17.69 12.97L16 14.05'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.801 13.801V13.801C3.807 13.801 3 12.995 3 12V12C3 11.006 3.806 10.2 4.801 10.2V10.2C5.795 10.2 6.602 11.006 6.602 12.001V12.001C6.6 12.995 5.794 13.801 4.801 13.801Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M8.69995 14.5L10.39 15.58'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M6.31006 11.03L8.00006 9.95001'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M3 18V19C3 20.105 3.895 21 5 21H6'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M21 6V5C21 3.895 20.105 3 19 3H18'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M6 3H5C3.895 3 3 3.895 3 5V6'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M18 21H19C20.105 21 21 20.105 21 19V18'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
    </svg>
)
export const IconSettings: React.FC = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M18 5H21'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M3 5H14'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M10 12H21'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M3 12H6'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M18 19H21'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M3 19H14'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M17.4142 3.58579C18.1953 4.36684 18.1953 5.63317 17.4142 6.41422C16.6332 7.19527 15.3668 7.19527 14.5858 6.41422C13.8047 5.63317 13.8047 4.36684 14.5858 3.58579C15.3668 2.80474 16.6332 2.80474 17.4142 3.58579'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M9.41422 10.5858C10.1953 11.3669 10.1953 12.6332 9.41422 13.4142C8.63317 14.1953 7.36684 14.1953 6.58579 13.4142C5.80474 12.6332 5.80474 11.3669 6.58579 10.5858C7.36684 9.80475 8.63317 9.80475 9.41422 10.5858'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
        <path
            d='M17.4142 17.5858C18.1953 18.3669 18.1953 19.6332 17.4142 20.4142C16.6332 21.1953 15.3668 21.1953 14.5858 20.4142C13.8047 19.6332 13.8047 18.3669 14.5858 17.5858C15.3668 16.8048 16.6332 16.8048 17.4142 17.5858'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        ></path>
    </svg>
)

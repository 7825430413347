// @ts-ignore
import styles from './styles.module.css'

const Introduction: React.FC = () => {
    return (
        <div>
            <div className='pageTitle'>Introduction</div>
            <div className={styles.text}>
                Softnote API Documentation Introduction Enabling DEXs, CEXs, and
                Wallets to Integrate Softnote Functionality
            </div>
            <div className='pageSubTitle'>Abstract</div>
            <div className={styles.smallText}>
                In the rapidly evolving landscape of decentralized and
                centralized exchanges (DEXs and CEXs) and digital wallets, the
                need for seamless interoperability and functionality is
                paramount. Recognizing the growing demand from these platforms
                to integrate Softnotes, Tectum has designed an application
                programming interface (API) to facilitate this integration. This
                API not only allows platforms to retrieve key information about
                the Softnote but also provides functionalities for changing its
                ownership via passcode modifications. The aim is to pave the way
                for a more fluid and secure transaction experience across
                various crypto trading and storage platforms.
            </div>
            <div className='pageSubTitle'>API Features and Functions</div>
            <div className={styles.smallText}>
                The API has been architected to extend the capabilities of
                Softnotes to DEXs, CEXs, and other wallet developers. The API
                exposes the following functions and information retrieval
                methods, each serving distinct purposes in managing and
                transacting Softnotes:
            </div>
            <div className={styles.smallText}>
                Passcode: Allows both read and write operations, enabling
                platforms to change Softnote ownership through passcode
                alteration.
            </div>
            <div className={styles.smallText}>
                Fill/Virgin/Born Status: Provides read-only access to the status
                of the Softnote, elucidating whether it is filled, virgin, or
                born. Denomination: Provides read-only access to the Softnote's
                denomination, assisting platforms in transaction and trading
                calculations.
            </div>
            <div className={styles.smallText}>
                Market Value: Provides read-only access to the current market
                value of the Softnote, essential for market analysis and
                transaction decision-making.
            </div>
            <div className={styles.smallText}>
                TET Fee: Allows platforms to read and pay the Tectum fee
                associated with the Softnote.
            </div>
            <div className='pageSubTitle'>Scope of Documentation</div>
            <div className={styles.smallText}>
                The following API documentation will elucidate each of the
                aforementioned functionalities in detail, providing example
                calls, expected outputs, and potential use cases. This will
                serve as a comprehensive guide for DEXs, CEXs, and wallet
                developers looking to integrate Softnote functionality into
                their platforms.
            </div>
            <div className='pageSubTitle'>Conclusion</div>
            <div className={styles.smallText}>
                The API provides an innovative mechanism for extending the
                features of Tectum's Softnotes to a broader ecosystem, including
                decentralized and centralized exchanges as well as other
                cryptocurrency wallets. By offering functionalities ranging from
                passcode modifications to real-time market value retrieval, the
                API is poised to significantly enhance user experience and
                transactional fluidity across platforms. Through this
                initiative, Tectum aims to contribute to the ongoing efforts to
                streamline cryptocurrency transactions and bolster security
                measures in the ever-evolving digital asset landscape.
            </div>
        </div>
    )
}

export default Introduction

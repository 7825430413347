// @ts-ignore
import styles from './styles.module.css'
// @ts-ignore
import img1 from '../../assets/icons/bills/1.svg'
// @ts-ignore
import img2 from '../../assets/icons/bills/2.svg'
// @ts-ignore
import img3 from '../../assets/icons/bills/3.svg'
// @ts-ignore
import img4 from '../../assets/icons/bills/4.svg'
// @ts-ignore
import archive from '../../assets/icons/archive.svg'
import { DOWNLOAD_SOFTNOTES_ZIP } from '../../utils/constants'

const SoftnoteImages: React.FC = () => {
    return (
        <div>
            <div className={styles.title}>Softnote Images</div>
            <div className={styles.softnoteImages}>
                <a
                    type='file'
                    title='Download zip'
                    href={DOWNLOAD_SOFTNOTES_ZIP}
                    className={`buttonBlue ${styles.download}`}
                >
                    <img src={archive} alt='Download' />
                    <div className={styles.buttonText}>
                        Download the archive of resources
                    </div>
                </a>
                <div className={styles.text}>Examples of banknotes</div>
                <div className={styles.wrapper}>
                    <div className={styles.pic}>
                        <img
                            src={img1}
                            className={styles.img}
                            alt='Bill_USDT'
                        />
                    </div>
                    <div className={styles.pic}>
                        <img src={img2} className={styles.img} alt='Bill_ETH' />
                    </div>
                    <div className={styles.pic}>
                        <img src={img3} className={styles.img} alt='Bill_BTC' />
                    </div>
                    <div className={styles.pic}>
                        <img src={img4} className={styles.img} alt='Bill_TRX' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SoftnoteImages

// @ts-ignore
import styles from './styles.module.css'
// @ts-ignore
import IconLogoSoftNote from '../../../assets/icons/logo_softnote.svg'
// @ts-ignore
import IconLogoSoftNoteMini from '../../../assets/icons/logo_softnote_mini.svg'
import classnames from 'classnames'
import { MEDIA_QUERY_TABLET, useMediaQuery } from '../../../hooks/useMediaQuery'
import { useLocation } from 'react-router-dom'
import Navbar from '../NavBar'

const HeaderComponent: React.FC = () => {
    const isMobile = useMediaQuery(MEDIA_QUERY_TABLET)

    const IconLogo = isMobile ? IconLogoSoftNote : IconLogoSoftNoteMini
    const { pathname } = useLocation()

    return (
        <header className={styles.wrapper}>
            <div className={styles.container}>
                <img src={IconLogo} alt='logo' />

                <div className={styles.navbar}>
                    <Navbar />
                </div>
                <div
                    className={classnames(
                        styles.userCard,
                        pathname === '/' ? styles.userCardMainPage : ''
                    )}
                ></div>
            </div>
        </header>
    )
}
export default HeaderComponent

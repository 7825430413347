interface Props {
    color?: string
}

const IconSideBar: React.FC<Props> = props => {
    const { color = 'white' } = props

    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M4.66666 9.33329H9.33332'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.04168 4.78333L6.47501 7.34999L4.95834 5.83333'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.91667 12.25H4.08333C2.8 12.25 1.75 11.2 1.75 9.91667V4.08333C1.75 2.8 2.8 1.75 4.08333 1.75H9.91667C11.2 1.75 12.25 2.8 12.25 4.08333V9.91667C12.25 11.2 11.2 12.25 9.91667 12.25Z'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default IconSideBar

import Request from '../../com/shared/Request'
import { apiData } from '../../data'
import { ApiData } from '../../data/types'
// @ts-ignore
import styles from './styles.module.css'

const SoftNoteApi: React.FC = () => {
    return (
        <div>
            <div className='pageTitle'>SoftNote methods</div>
            <div className={styles.title}>
                Base url: https://api.softnote.com/v1/public/
            </div>
            <div className='pageSubtitle'>
                SoftNote's API keys should be used when using SoftNote API.
            </div>
            <div className={styles.text}>
                When using an API key, pass it in an Authorization header:{' '}
                {'Authorization: <Your API Key>'}
            </div>
            <div className={styles.title}> Responses HTTP Return codes</div>
            <ul>
                <li>2XX - The API call was successful.</li>
                <li>
                    4XX - The API call had an error in the parameters. The error
                    will be encoded in the body of the response.
                </li>
                <li>
                    5XX - The API call was unsuccessful. You should retry later.
                </li>
            </ul>
            {apiData.map((el: ApiData) => (
                <Request
                    key={el.url}
                    parametrs={el.parametrs}
                    type={el.type}
                    url={el.url}
                    desc={el.desc}
                    code={el.code}
                />
            ))}
        </div>
    )
}

export default SoftNoteApi
